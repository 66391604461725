import store from '@/store'
/* eslint-disable import/prefer-default-export */
export const premiumSeances = () => {
  // eslint-disable-next-line arrow-body-style
  const fetchSeances = payload => {
    return store.dispatch('seance-rules/fetchSeances', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchSeance = seanceId => {
    return store.dispatch('seance-rules/fetchSeance', seanceId)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchSeanceStatsBySource = payload => {
    return store.dispatch('seance-rules/fetchSeanceStatsBySource', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchSeanceStatsByTotals = payload => {
    return store.dispatch('seance-rules/fetchSeanceStatsByTotalCounts', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchSeancesActivity = payload => {
    return store.dispatch('seance-rules/fetchSeancesActivity', payload)
  }

  return {
    fetchSeances,
    fetchSeance,
    fetchSeanceStatsBySource,
    fetchSeanceStatsByTotals,
    fetchSeancesActivity,
  }
}
